<template>
  <el-card id="applyAudit">
    <header>
      <h2 style="display: flex; align-items: center">
        申请号码审核<span v-if="showTitleText">(可审核来自组长、坐席端，客户列表--【公海号码申请】的申请审批)</span>
        <el-tooltip class="item" effect="dark" content="点击查看详情说明" placement="top">
          <img v-if="!showTitleText" src="@/assets/images/what.png" alt=""
            style="width: 30px; height: 30px; cursor: pointer" @click="showTitleText = true" />
        </el-tooltip>
      </h2>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>企业名称</span>
            <el-input v-model="company" placeholder="请输入企业名称" size="mini"></el-input>
          </div>
          <div class="left-wrap">
            <span>客户号码</span>
            <el-input v-model="activePhone" placeholder="请输入客户号码" size="mini"></el-input>
          </div>
          <div class="left-wrap">
            <span>号码状态</span>
            <el-select v-model="p_status" placeholder="请选择状态" size="mini">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap">
            <span>申请状态</span>
            <el-select v-model="f_status" placeholder="请选择状态" size="mini">
              <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="right">
          <el-button type="primary" icon="el-icon-search" @click="handleSearch" size="mini"
            class="searchBtn">查找</el-button>
          <el-button type="primary" icon="el-icon-refresh" @click="reset" size="mini" class="reset">重置</el-button>
          <el-button type="primary" @click="auditAll" class="searchBtn" size="mini"
            v-if="roleName == '企业'">批量审核</el-button>
        </div>
      </div>
    </header>
    <!-- <hr> -->
    <hr>
    <main>
      <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
        @selection-change="handleSelectionChange">
        <!-- 多选按钮 -->
        <el-table-column type="selection" width="55" align="center"></el-table-column><el-table-column prop="company"
          label="企业名称" align="center"></el-table-column>
        <el-table-column prop="active_phone" label="客户号码" align="center"></el-table-column>
        <el-table-column prop="customer_phone" label="客户姓名" align="center"></el-table-column>
        <el-table-column prop="realname" label="申请人姓名" align="center"></el-table-column>
        <el-table-column prop="p_status" label="号码状态" align="center">
          <template #default="scope">
            <span v-if="scope.row.p_status == 0">一般客户</span>
            <span v-if="scope.row.p_status == 1">意向客户</span>
            <span v-if="scope.row.p_status == 2">无意向客户</span>
            <span v-if="scope.row.p_status == 3">成交客户</span>
          </template>
        </el-table-column>
        <el-table-column prop="f_status" label="申请状态" align="center">
          <template v-slot="scope">
            <div :class="fontClass(scope.row.f_status)">
              {{ statusClass(scope.row.f_status) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center" v-if="roleName == '企业'">
          <template v-slot="scope">
            <el-button v-show="distinguishStatus(scope.row.f_status)" type="primary" plain disabled
              @click="audit(scope.row)" size="mini">审 核</el-button>
            <el-button type="primary" v-show="!distinguishStatus(scope.row.f_status)" @click="audit(scope.row)"
              size="mini">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex" :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]" :total="pageTotal" @size-change="handleSizeChange"
          @current-change="handlePageChange"></el-pagination>
      </div>
    </main>

    <!-- 批量审核弹出框 -->
    <el-dialog title="批量审核" :visible.sync="auditAllVisible" width="30%">
      <span>审核这段号码</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="auditAllVisible = false">取 消</el-button>
          <el-button type="danger" @click="submitauditAll(2)">驳 回</el-button>

          <el-button type="success" @click="submitauditAll(1)">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 审核弹出框 -->
    <el-dialog title="批量审核" :visible.sync="auditVisible" width="30%">
      <span>审核这段号码</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="auditVisible = false">取 消</el-button>
          <el-button type="danger" @click="submitaudit(2)">驳 回</el-button>

          <el-button type="success" @click="submitaudit(1)">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import { phoneApplyForList, phoneApplyForUpdate } from "@/api/phoneApplyFor";
export default {
  data() {
    return {
      timer: null,
      showTitleText: false,
      company: null,
      activePhone: null,
      p_status: null,
      f_status: 0,
      auditVisible: false,
      auditAllVisible: false,
      numsArray: [],
      options: [
        {
          value: 0,
          label: "一般客户",
        },
        {
          value: 1,
          label: "意向客户",
        },
        {
          value: 2,
          label: "无意向客户",
        },
        {
          value: 3,
          label: "成交客户",
        },
        {
          value: 5,
          label: "无状态",
        },
        {
          value: 6,
          label: "未接通",
        },
      ],
      options1: [
        {
          value: 0,
          label: "申请待审核",
        },
        {
          value: 1,
          label: "申请通过",
        },
        {
          value: 2,
          label: "申请驳回",
        },
      ],
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    this.roleName = localStorage.getItem("roleName");
    this.getList();
  },
  methods: {
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 展示
    getList() {
      if (this.timer) {
        Message.warning("请勿频繁操作~");
      }
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        let params = {
          company: this.company,
          activePhone: this.activePhone,
          p_status: this.p_status,
          f_status: this.f_status,
          page: this.page,
          size: this.size,
        };
        let res = phoneApplyForList(params);
        res.then((res) => {
          if (res.data.statusCode == '50000') {
          } else {
            if (res.data.statusCode == '50000') {
            } else {
              this.tableData = res.data.data;
              this.pageTotal = res.data.total;
            }
          }
        });
        this.timer = null
      }, 500);
    },
    // 审核
    audit(scope) {
      // console.log('pid',pid);
      this.pid = scope.pid;
      this.auditVisible = true;
    },
    // 确认审核
    submitaudit(status) {
      let params = {
        listId: [this.pid],
        roleDepart: 0,
        status,
      };
      let res = phoneApplyForUpdate(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.auditVisible = false;
        this.getList();
      });
    },
    // 批量审核
    auditAll() {
      if (this.numsArray.length == 0) {
        Message.warning("未选中号码");
        return;
      }
      this.auditAllVisible = true;
    },
    // 确认批量审核
    submitauditAll(status) {
      let params = {
        listId: this.numsArray,
        status,
        roleDepart: 0,
      };
      let res = phoneApplyForUpdate(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.auditAllVisible = false;
        this.page = 1
        this.getList();
      });
    },

    //选择table表格
    handleSelectionChange(val) {
      this.numsArray = [];
      val.forEach((item) => {
        this.numsArray.push(item.pid);
      });
    },
    // 搜索
    handleSearch() {
      this.page = 1;
      this.getList();
    },
    // 重置
    reset() {
      this.company = null;
      this.activePhone = null;
      this.p_status = null;
      this.f_status = 0;
      this.getList();
    },
    // 按钮状态
    distinguishStatus(f_status) {
      if (f_status != 0) {
        return true;
      }
    },
    // 状态分类
    statusClass(f_status) {
      // console.log("状态的值", f_status);
      if (f_status == null) {
        return "未申请";
      } else if (f_status == 0) {
        return "待审核";
      } else if (f_status == 1) {
        return "审核通过";
      } else if (f_status == 2) {
        return "审核未通过";
      }
    },
    // 状态分类字体样式
    fontClass(f_status) {
      // console.log("状态的值", f_status);
      if (f_status == null) {
        return "f_statusn";
      } else if (f_status == 0) {
        return "f_status0";
      } else if (f_status == 1) {
        return "f_status1";
      } else if (f_status == 2) {
        return "f_status2";
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--success,
.el-button--primary {
  color: #FFF;
  background-color: #3278FE;
  border-color: #3278FE;

  &:hover {
    opacity: 0.7;
  }
}

#applyAudit {
  height: 100%;
  overflow-y: scroll;

  header {
    h1 {
      font-weight: normal;

      >span {
        font-size: 18px;
        color: #606369;
      }
    }

    .search {
      display: flex;
      margin-top: 10px;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        flex: 1;
        line-height: 30px;

        .left-wrap {
          display: flex;
          align-items: center;

          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        // margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }

  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>