/**
 * phoneApplyFor
 * 号码申请
 */

import request from '@/utils/request'

// 展示
export const phoneApplyForList = (data) => {
    return request({
        method: 'post',
        url: '/phoneApplyFor/list',
        data
    })
}
// 编辑
export const phoneApplyForUpdate = (data) => {
    return request({
        method: 'post',
        url: '/phoneApplyFor/update-phoneApplyFor',
        data
    })
}
// 
export const phoneApplyUserPhoneApplyFor = (data) => {
    return request({
        method: 'post',
        url: '/phoneApplyFor/user-phoneApplyFor',
        data
    })
}
